import { pdf_to_word, word_to_pdf } from "../methods/pdfController";
export const ImageTools = [
  {
    name: "compress_img",
    icon: "",
    title: "Compress Image",
    description: "",
    file_types: "",
    tool_type: "optimize_img",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "remove_bg_img",
    icon: "",
    title: "Remove background",
    description: "",
    file_types: "",
    tool_type: "optimize_img",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "convert_to_jpg",
    icon: "",
    title: "Convert to JPG",
    description: "",
    file_types: "",
    tool_type: "convert",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "convert_from_jpg",
    icon: "",
    title: "Convert from JPG",
    description: "",
    file_types: "",
    tool_type: "convert",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "convert_from_jpg",
    icon: "",
    title: "HTML to JPG",
    description: "",
    file_types: "",
    tool_type: "convert",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },

];

export const ImageToolsTitles = [
 
  {
    name: "OPTIMIZE IMAGE",
    slug: "optimize_img",
  },
  {
    name: "CONVERT",
    slug: "convert",
  },
 
];
