import React from 'react'
import Navbar from '../components/Navbar'

const Home = () => {
  return (<div className='w-full'>
  <Navbar/>
    <div className='bg-background w-full h-screen flex-col px-16'>
        <section id='title'>
        <div className='w-full py-3 gap-3'>
            <h1 className='font-bold text-4xl text-center leading-10 py-3'>Every tool you need to work with PDFs/Images in one place</h1>
           <center>
       
            <h1 className='text-center md:line-clamp-2 w-2/3 leading-7 text-xl pb-3'>Every tool you need to use PDFs/Images, at your fingertips. All are 100% FREE and easy to use!  compress, convert PDFs/Images with just a few clicks.</h1>
            </center>
        </div>
        </section>
        <section id='tools'>
            <h1 className='font-bold text-4xl'>Pdf Tools</h1>
            <div>
                
            </div>
            <h1 className='font-bold text-4xl'>Image Tools</h1>
            <div>
                
            </div>

        </section>

    </div>
    </div>
  )
}

export default Home