import { pdf_to_word, word_to_pdf } from "../methods/pdfController";
export const PdfTools = [
  {
    name: "word_to_pdf",
    icon: "",
    title: "Word To PDF",
    description: "",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "powerpoint_to_pdf",
    icon: "",
    title: "POWERPOINT To PDF",
    description: "",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "excel_to_pdf",
    icon: "",
    title: "EXCEL To PDF",
    description: "",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "html_to_pdf",
    icon: "",
    title: "HTML To PDF",
    description: "",
    file_types: "",
    tool_type: "convert_to_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },

  {
    name: "pdf_to_word",
    icon: "",
    title: "PDF To Word ",
    description: "",
    file_types: "",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "pdf_to_powerpoint",
    icon: "",
    title: "PDF To POWERPOINT ",
    description: "",
    file_types: "",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "pdf_to_excel",
    icon: "",
    title: "PDF To EXCEL ",
    description: "",
    file_types: "",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "pdf_to_jpg",
    icon: "",
    title: "PDF To JPG ",
    description: "",
    file_types: "",
    tool_type: "convert_from_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },

  {
    name: "compress_pdf",
    icon: "",
    title: "Compress PDF",
    description: "",
    file_types: "",
    tool_type: "optimize_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
  {
    name: "repair_pdf",
    icon: "",
    title: "REPAIR PDF",
    description: "",
    file_types: "",
    tool_type: "optimize_pdf",
    enable_file_types: "",
    function: (e) => word_to_pdf(e),
  },
];

export const PdfToolsTitles = [
  {
    name: "CONVERT TO PDF",
    slug: "convert_to_pdf",
  },
  {
    name: "CONVERT FROM PDF",
    slug: "convert_from_pdf",
  },
  {
    name: "OPTIMIZE PDF",
    slug: "optimize_pdf",
  },
];
